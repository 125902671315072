// source: protobuf/validate/validate.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_descriptor_pb = require('google-protobuf/google/protobuf/descriptor_pb.js');
goog.object.extend(proto, google_protobuf_descriptor_pb);
var google_protobuf_duration_pb = require('google-protobuf/google/protobuf/duration_pb.js');
goog.object.extend(proto, google_protobuf_duration_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.validate.AnyRules', null, global);
goog.exportSymbol('proto.validate.BoolRules', null, global);
goog.exportSymbol('proto.validate.BytesRules', null, global);
goog.exportSymbol('proto.validate.BytesRules.WellKnownCase', null, global);
goog.exportSymbol('proto.validate.DoubleRules', null, global);
goog.exportSymbol('proto.validate.DurationRules', null, global);
goog.exportSymbol('proto.validate.EnumRules', null, global);
goog.exportSymbol('proto.validate.FieldRules', null, global);
goog.exportSymbol('proto.validate.FieldRules.TypeCase', null, global);
goog.exportSymbol('proto.validate.Fixed32Rules', null, global);
goog.exportSymbol('proto.validate.Fixed64Rules', null, global);
goog.exportSymbol('proto.validate.FloatRules', null, global);
goog.exportSymbol('proto.validate.Int32Rules', null, global);
goog.exportSymbol('proto.validate.Int64Rules', null, global);
goog.exportSymbol('proto.validate.KnownRegex', null, global);
goog.exportSymbol('proto.validate.MapRules', null, global);
goog.exportSymbol('proto.validate.MessageRules', null, global);
goog.exportSymbol('proto.validate.RepeatedRules', null, global);
goog.exportSymbol('proto.validate.SFixed32Rules', null, global);
goog.exportSymbol('proto.validate.SFixed64Rules', null, global);
goog.exportSymbol('proto.validate.SInt32Rules', null, global);
goog.exportSymbol('proto.validate.SInt64Rules', null, global);
goog.exportSymbol('proto.validate.StringRules', null, global);
goog.exportSymbol('proto.validate.StringRules.WellKnownCase', null, global);
goog.exportSymbol('proto.validate.TimestampRules', null, global);
goog.exportSymbol('proto.validate.UInt32Rules', null, global);
goog.exportSymbol('proto.validate.UInt64Rules', null, global);
goog.exportSymbol('proto.validate.disabled', null, global);
goog.exportSymbol('proto.validate.ignored', null, global);
goog.exportSymbol('proto.validate.required', null, global);
goog.exportSymbol('proto.validate.rules', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.FieldRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.validate.FieldRules.oneofGroups_);
};
goog.inherits(proto.validate.FieldRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.FieldRules.displayName = 'proto.validate.FieldRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.FloatRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.FloatRules.repeatedFields_, null);
};
goog.inherits(proto.validate.FloatRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.FloatRules.displayName = 'proto.validate.FloatRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.DoubleRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.DoubleRules.repeatedFields_, null);
};
goog.inherits(proto.validate.DoubleRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.DoubleRules.displayName = 'proto.validate.DoubleRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.Int32Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.Int32Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.Int32Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.Int32Rules.displayName = 'proto.validate.Int32Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.Int64Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.Int64Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.Int64Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.Int64Rules.displayName = 'proto.validate.Int64Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.UInt32Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.UInt32Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.UInt32Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.UInt32Rules.displayName = 'proto.validate.UInt32Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.UInt64Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.UInt64Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.UInt64Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.UInt64Rules.displayName = 'proto.validate.UInt64Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.SInt32Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.SInt32Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.SInt32Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.SInt32Rules.displayName = 'proto.validate.SInt32Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.SInt64Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.SInt64Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.SInt64Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.SInt64Rules.displayName = 'proto.validate.SInt64Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.Fixed32Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.Fixed32Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.Fixed32Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.Fixed32Rules.displayName = 'proto.validate.Fixed32Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.Fixed64Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.Fixed64Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.Fixed64Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.Fixed64Rules.displayName = 'proto.validate.Fixed64Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.SFixed32Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.SFixed32Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.SFixed32Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.SFixed32Rules.displayName = 'proto.validate.SFixed32Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.SFixed64Rules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.SFixed64Rules.repeatedFields_, null);
};
goog.inherits(proto.validate.SFixed64Rules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.SFixed64Rules.displayName = 'proto.validate.SFixed64Rules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.BoolRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.validate.BoolRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.BoolRules.displayName = 'proto.validate.BoolRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.StringRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.StringRules.repeatedFields_, proto.validate.StringRules.oneofGroups_);
};
goog.inherits(proto.validate.StringRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.StringRules.displayName = 'proto.validate.StringRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.BytesRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.BytesRules.repeatedFields_, proto.validate.BytesRules.oneofGroups_);
};
goog.inherits(proto.validate.BytesRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.BytesRules.displayName = 'proto.validate.BytesRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.EnumRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.EnumRules.repeatedFields_, null);
};
goog.inherits(proto.validate.EnumRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.EnumRules.displayName = 'proto.validate.EnumRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.MessageRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.validate.MessageRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.MessageRules.displayName = 'proto.validate.MessageRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.RepeatedRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.validate.RepeatedRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.RepeatedRules.displayName = 'proto.validate.RepeatedRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.MapRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.validate.MapRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.MapRules.displayName = 'proto.validate.MapRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.AnyRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.AnyRules.repeatedFields_, null);
};
goog.inherits(proto.validate.AnyRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.AnyRules.displayName = 'proto.validate.AnyRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.DurationRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.validate.DurationRules.repeatedFields_, null);
};
goog.inherits(proto.validate.DurationRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.DurationRules.displayName = 'proto.validate.DurationRules';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.validate.TimestampRules = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.validate.TimestampRules, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.validate.TimestampRules.displayName = 'proto.validate.TimestampRules';
}

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.validate.FieldRules.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,18,19,20,21,22]];

/**
 * @enum {number}
 */
proto.validate.FieldRules.TypeCase = {
  TYPE_NOT_SET: 0,
  FLOAT: 1,
  DOUBLE: 2,
  INT32: 3,
  INT64: 4,
  UINT32: 5,
  UINT64: 6,
  SINT32: 7,
  SINT64: 8,
  FIXED32: 9,
  FIXED64: 10,
  SFIXED32: 11,
  SFIXED64: 12,
  BOOL: 13,
  STRING: 14,
  BYTES: 15,
  ENUM: 16,
  REPEATED: 18,
  MAP: 19,
  ANY: 20,
  DURATION: 21,
  TIMESTAMP: 22
};

/**
 * @return {proto.validate.FieldRules.TypeCase}
 */
proto.validate.FieldRules.prototype.getTypeCase = function() {
  return /** @type {proto.validate.FieldRules.TypeCase} */(jspb.Message.computeOneofCase(this, proto.validate.FieldRules.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.FieldRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.FieldRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.FieldRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.FieldRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: (f = msg.getMessage()) && proto.validate.MessageRules.toObject(includeInstance, f),
    pb_float: (f = msg.getFloat()) && proto.validate.FloatRules.toObject(includeInstance, f),
    pb_double: (f = msg.getDouble()) && proto.validate.DoubleRules.toObject(includeInstance, f),
    int32: (f = msg.getInt32()) && proto.validate.Int32Rules.toObject(includeInstance, f),
    int64: (f = msg.getInt64()) && proto.validate.Int64Rules.toObject(includeInstance, f),
    uint32: (f = msg.getUint32()) && proto.validate.UInt32Rules.toObject(includeInstance, f),
    uint64: (f = msg.getUint64()) && proto.validate.UInt64Rules.toObject(includeInstance, f),
    sint32: (f = msg.getSint32()) && proto.validate.SInt32Rules.toObject(includeInstance, f),
    sint64: (f = msg.getSint64()) && proto.validate.SInt64Rules.toObject(includeInstance, f),
    fixed32: (f = msg.getFixed32()) && proto.validate.Fixed32Rules.toObject(includeInstance, f),
    fixed64: (f = msg.getFixed64()) && proto.validate.Fixed64Rules.toObject(includeInstance, f),
    sfixed32: (f = msg.getSfixed32()) && proto.validate.SFixed32Rules.toObject(includeInstance, f),
    sfixed64: (f = msg.getSfixed64()) && proto.validate.SFixed64Rules.toObject(includeInstance, f),
    bool: (f = msg.getBool()) && proto.validate.BoolRules.toObject(includeInstance, f),
    string: (f = msg.getString()) && proto.validate.StringRules.toObject(includeInstance, f),
    bytes: (f = msg.getBytes()) && proto.validate.BytesRules.toObject(includeInstance, f),
    pb_enum: (f = msg.getEnum()) && proto.validate.EnumRules.toObject(includeInstance, f),
    repeated: (f = msg.getRepeated()) && proto.validate.RepeatedRules.toObject(includeInstance, f),
    map: (f = msg.getMap()) && proto.validate.MapRules.toObject(includeInstance, f),
    any: (f = msg.getAny()) && proto.validate.AnyRules.toObject(includeInstance, f),
    duration: (f = msg.getDuration()) && proto.validate.DurationRules.toObject(includeInstance, f),
    timestamp: (f = msg.getTimestamp()) && proto.validate.TimestampRules.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.FieldRules}
 */
proto.validate.FieldRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.FieldRules;
  return proto.validate.FieldRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.FieldRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.FieldRules}
 */
proto.validate.FieldRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 17:
      var value = new proto.validate.MessageRules;
      reader.readMessage(value,proto.validate.MessageRules.deserializeBinaryFromReader);
      msg.setMessage(value);
      break;
    case 1:
      var value = new proto.validate.FloatRules;
      reader.readMessage(value,proto.validate.FloatRules.deserializeBinaryFromReader);
      msg.setFloat(value);
      break;
    case 2:
      var value = new proto.validate.DoubleRules;
      reader.readMessage(value,proto.validate.DoubleRules.deserializeBinaryFromReader);
      msg.setDouble(value);
      break;
    case 3:
      var value = new proto.validate.Int32Rules;
      reader.readMessage(value,proto.validate.Int32Rules.deserializeBinaryFromReader);
      msg.setInt32(value);
      break;
    case 4:
      var value = new proto.validate.Int64Rules;
      reader.readMessage(value,proto.validate.Int64Rules.deserializeBinaryFromReader);
      msg.setInt64(value);
      break;
    case 5:
      var value = new proto.validate.UInt32Rules;
      reader.readMessage(value,proto.validate.UInt32Rules.deserializeBinaryFromReader);
      msg.setUint32(value);
      break;
    case 6:
      var value = new proto.validate.UInt64Rules;
      reader.readMessage(value,proto.validate.UInt64Rules.deserializeBinaryFromReader);
      msg.setUint64(value);
      break;
    case 7:
      var value = new proto.validate.SInt32Rules;
      reader.readMessage(value,proto.validate.SInt32Rules.deserializeBinaryFromReader);
      msg.setSint32(value);
      break;
    case 8:
      var value = new proto.validate.SInt64Rules;
      reader.readMessage(value,proto.validate.SInt64Rules.deserializeBinaryFromReader);
      msg.setSint64(value);
      break;
    case 9:
      var value = new proto.validate.Fixed32Rules;
      reader.readMessage(value,proto.validate.Fixed32Rules.deserializeBinaryFromReader);
      msg.setFixed32(value);
      break;
    case 10:
      var value = new proto.validate.Fixed64Rules;
      reader.readMessage(value,proto.validate.Fixed64Rules.deserializeBinaryFromReader);
      msg.setFixed64(value);
      break;
    case 11:
      var value = new proto.validate.SFixed32Rules;
      reader.readMessage(value,proto.validate.SFixed32Rules.deserializeBinaryFromReader);
      msg.setSfixed32(value);
      break;
    case 12:
      var value = new proto.validate.SFixed64Rules;
      reader.readMessage(value,proto.validate.SFixed64Rules.deserializeBinaryFromReader);
      msg.setSfixed64(value);
      break;
    case 13:
      var value = new proto.validate.BoolRules;
      reader.readMessage(value,proto.validate.BoolRules.deserializeBinaryFromReader);
      msg.setBool(value);
      break;
    case 14:
      var value = new proto.validate.StringRules;
      reader.readMessage(value,proto.validate.StringRules.deserializeBinaryFromReader);
      msg.setString(value);
      break;
    case 15:
      var value = new proto.validate.BytesRules;
      reader.readMessage(value,proto.validate.BytesRules.deserializeBinaryFromReader);
      msg.setBytes(value);
      break;
    case 16:
      var value = new proto.validate.EnumRules;
      reader.readMessage(value,proto.validate.EnumRules.deserializeBinaryFromReader);
      msg.setEnum(value);
      break;
    case 18:
      var value = new proto.validate.RepeatedRules;
      reader.readMessage(value,proto.validate.RepeatedRules.deserializeBinaryFromReader);
      msg.setRepeated(value);
      break;
    case 19:
      var value = new proto.validate.MapRules;
      reader.readMessage(value,proto.validate.MapRules.deserializeBinaryFromReader);
      msg.setMap(value);
      break;
    case 20:
      var value = new proto.validate.AnyRules;
      reader.readMessage(value,proto.validate.AnyRules.deserializeBinaryFromReader);
      msg.setAny(value);
      break;
    case 21:
      var value = new proto.validate.DurationRules;
      reader.readMessage(value,proto.validate.DurationRules.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    case 22:
      var value = new proto.validate.TimestampRules;
      reader.readMessage(value,proto.validate.TimestampRules.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.FieldRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.FieldRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.FieldRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.FieldRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.validate.MessageRules.serializeBinaryToWriter
    );
  }
  f = message.getFloat();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.validate.FloatRules.serializeBinaryToWriter
    );
  }
  f = message.getDouble();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.validate.DoubleRules.serializeBinaryToWriter
    );
  }
  f = message.getInt32();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.validate.Int32Rules.serializeBinaryToWriter
    );
  }
  f = message.getInt64();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.validate.Int64Rules.serializeBinaryToWriter
    );
  }
  f = message.getUint32();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.validate.UInt32Rules.serializeBinaryToWriter
    );
  }
  f = message.getUint64();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.validate.UInt64Rules.serializeBinaryToWriter
    );
  }
  f = message.getSint32();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.validate.SInt32Rules.serializeBinaryToWriter
    );
  }
  f = message.getSint64();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.validate.SInt64Rules.serializeBinaryToWriter
    );
  }
  f = message.getFixed32();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.validate.Fixed32Rules.serializeBinaryToWriter
    );
  }
  f = message.getFixed64();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.validate.Fixed64Rules.serializeBinaryToWriter
    );
  }
  f = message.getSfixed32();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.validate.SFixed32Rules.serializeBinaryToWriter
    );
  }
  f = message.getSfixed64();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.validate.SFixed64Rules.serializeBinaryToWriter
    );
  }
  f = message.getBool();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.validate.BoolRules.serializeBinaryToWriter
    );
  }
  f = message.getString();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.validate.StringRules.serializeBinaryToWriter
    );
  }
  f = message.getBytes();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.validate.BytesRules.serializeBinaryToWriter
    );
  }
  f = message.getEnum();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.validate.EnumRules.serializeBinaryToWriter
    );
  }
  f = message.getRepeated();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.validate.RepeatedRules.serializeBinaryToWriter
    );
  }
  f = message.getMap();
  if (f != null) {
    writer.writeMessage(
      19,
      f,
      proto.validate.MapRules.serializeBinaryToWriter
    );
  }
  f = message.getAny();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      proto.validate.AnyRules.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      proto.validate.DurationRules.serializeBinaryToWriter
    );
  }
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.validate.TimestampRules.serializeBinaryToWriter
    );
  }
};


/**
 * optional MessageRules message = 17;
 * @return {?proto.validate.MessageRules}
 */
proto.validate.FieldRules.prototype.getMessage = function() {
  return /** @type{?proto.validate.MessageRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.MessageRules, 17));
};


/**
 * @param {?proto.validate.MessageRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setMessage = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearMessage = function() {
  return this.setMessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasMessage = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional FloatRules float = 1;
 * @return {?proto.validate.FloatRules}
 */
proto.validate.FieldRules.prototype.getFloat = function() {
  return /** @type{?proto.validate.FloatRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.FloatRules, 1));
};


/**
 * @param {?proto.validate.FloatRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setFloat = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearFloat = function() {
  return this.setFloat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasFloat = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DoubleRules double = 2;
 * @return {?proto.validate.DoubleRules}
 */
proto.validate.FieldRules.prototype.getDouble = function() {
  return /** @type{?proto.validate.DoubleRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.DoubleRules, 2));
};


/**
 * @param {?proto.validate.DoubleRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setDouble = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearDouble = function() {
  return this.setDouble(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasDouble = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Int32Rules int32 = 3;
 * @return {?proto.validate.Int32Rules}
 */
proto.validate.FieldRules.prototype.getInt32 = function() {
  return /** @type{?proto.validate.Int32Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.Int32Rules, 3));
};


/**
 * @param {?proto.validate.Int32Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setInt32 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearInt32 = function() {
  return this.setInt32(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasInt32 = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Int64Rules int64 = 4;
 * @return {?proto.validate.Int64Rules}
 */
proto.validate.FieldRules.prototype.getInt64 = function() {
  return /** @type{?proto.validate.Int64Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.Int64Rules, 4));
};


/**
 * @param {?proto.validate.Int64Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setInt64 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearInt64 = function() {
  return this.setInt64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasInt64 = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UInt32Rules uint32 = 5;
 * @return {?proto.validate.UInt32Rules}
 */
proto.validate.FieldRules.prototype.getUint32 = function() {
  return /** @type{?proto.validate.UInt32Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.UInt32Rules, 5));
};


/**
 * @param {?proto.validate.UInt32Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setUint32 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearUint32 = function() {
  return this.setUint32(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasUint32 = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UInt64Rules uint64 = 6;
 * @return {?proto.validate.UInt64Rules}
 */
proto.validate.FieldRules.prototype.getUint64 = function() {
  return /** @type{?proto.validate.UInt64Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.UInt64Rules, 6));
};


/**
 * @param {?proto.validate.UInt64Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setUint64 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearUint64 = function() {
  return this.setUint64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasUint64 = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SInt32Rules sint32 = 7;
 * @return {?proto.validate.SInt32Rules}
 */
proto.validate.FieldRules.prototype.getSint32 = function() {
  return /** @type{?proto.validate.SInt32Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.SInt32Rules, 7));
};


/**
 * @param {?proto.validate.SInt32Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setSint32 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearSint32 = function() {
  return this.setSint32(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasSint32 = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SInt64Rules sint64 = 8;
 * @return {?proto.validate.SInt64Rules}
 */
proto.validate.FieldRules.prototype.getSint64 = function() {
  return /** @type{?proto.validate.SInt64Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.SInt64Rules, 8));
};


/**
 * @param {?proto.validate.SInt64Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setSint64 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearSint64 = function() {
  return this.setSint64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasSint64 = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional Fixed32Rules fixed32 = 9;
 * @return {?proto.validate.Fixed32Rules}
 */
proto.validate.FieldRules.prototype.getFixed32 = function() {
  return /** @type{?proto.validate.Fixed32Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.Fixed32Rules, 9));
};


/**
 * @param {?proto.validate.Fixed32Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setFixed32 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 9, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearFixed32 = function() {
  return this.setFixed32(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasFixed32 = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Fixed64Rules fixed64 = 10;
 * @return {?proto.validate.Fixed64Rules}
 */
proto.validate.FieldRules.prototype.getFixed64 = function() {
  return /** @type{?proto.validate.Fixed64Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.Fixed64Rules, 10));
};


/**
 * @param {?proto.validate.Fixed64Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setFixed64 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearFixed64 = function() {
  return this.setFixed64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasFixed64 = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SFixed32Rules sfixed32 = 11;
 * @return {?proto.validate.SFixed32Rules}
 */
proto.validate.FieldRules.prototype.getSfixed32 = function() {
  return /** @type{?proto.validate.SFixed32Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.SFixed32Rules, 11));
};


/**
 * @param {?proto.validate.SFixed32Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setSfixed32 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearSfixed32 = function() {
  return this.setSfixed32(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasSfixed32 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional SFixed64Rules sfixed64 = 12;
 * @return {?proto.validate.SFixed64Rules}
 */
proto.validate.FieldRules.prototype.getSfixed64 = function() {
  return /** @type{?proto.validate.SFixed64Rules} */ (
    jspb.Message.getWrapperField(this, proto.validate.SFixed64Rules, 12));
};


/**
 * @param {?proto.validate.SFixed64Rules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setSfixed64 = function(value) {
  return jspb.Message.setOneofWrapperField(this, 12, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearSfixed64 = function() {
  return this.setSfixed64(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasSfixed64 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional BoolRules bool = 13;
 * @return {?proto.validate.BoolRules}
 */
proto.validate.FieldRules.prototype.getBool = function() {
  return /** @type{?proto.validate.BoolRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.BoolRules, 13));
};


/**
 * @param {?proto.validate.BoolRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setBool = function(value) {
  return jspb.Message.setOneofWrapperField(this, 13, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearBool = function() {
  return this.setBool(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasBool = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional StringRules string = 14;
 * @return {?proto.validate.StringRules}
 */
proto.validate.FieldRules.prototype.getString = function() {
  return /** @type{?proto.validate.StringRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.StringRules, 14));
};


/**
 * @param {?proto.validate.StringRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setString = function(value) {
  return jspb.Message.setOneofWrapperField(this, 14, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearString = function() {
  return this.setString(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasString = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional BytesRules bytes = 15;
 * @return {?proto.validate.BytesRules}
 */
proto.validate.FieldRules.prototype.getBytes = function() {
  return /** @type{?proto.validate.BytesRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.BytesRules, 15));
};


/**
 * @param {?proto.validate.BytesRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setBytes = function(value) {
  return jspb.Message.setOneofWrapperField(this, 15, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearBytes = function() {
  return this.setBytes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasBytes = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional EnumRules enum = 16;
 * @return {?proto.validate.EnumRules}
 */
proto.validate.FieldRules.prototype.getEnum = function() {
  return /** @type{?proto.validate.EnumRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.EnumRules, 16));
};


/**
 * @param {?proto.validate.EnumRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setEnum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 16, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearEnum = function() {
  return this.setEnum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasEnum = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional RepeatedRules repeated = 18;
 * @return {?proto.validate.RepeatedRules}
 */
proto.validate.FieldRules.prototype.getRepeated = function() {
  return /** @type{?proto.validate.RepeatedRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.RepeatedRules, 18));
};


/**
 * @param {?proto.validate.RepeatedRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setRepeated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 18, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearRepeated = function() {
  return this.setRepeated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasRepeated = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional MapRules map = 19;
 * @return {?proto.validate.MapRules}
 */
proto.validate.FieldRules.prototype.getMap = function() {
  return /** @type{?proto.validate.MapRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.MapRules, 19));
};


/**
 * @param {?proto.validate.MapRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setMap = function(value) {
  return jspb.Message.setOneofWrapperField(this, 19, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearMap = function() {
  return this.setMap(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasMap = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional AnyRules any = 20;
 * @return {?proto.validate.AnyRules}
 */
proto.validate.FieldRules.prototype.getAny = function() {
  return /** @type{?proto.validate.AnyRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.AnyRules, 20));
};


/**
 * @param {?proto.validate.AnyRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setAny = function(value) {
  return jspb.Message.setOneofWrapperField(this, 20, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearAny = function() {
  return this.setAny(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasAny = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional DurationRules duration = 21;
 * @return {?proto.validate.DurationRules}
 */
proto.validate.FieldRules.prototype.getDuration = function() {
  return /** @type{?proto.validate.DurationRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.DurationRules, 21));
};


/**
 * @param {?proto.validate.DurationRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setDuration = function(value) {
  return jspb.Message.setOneofWrapperField(this, 21, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional TimestampRules timestamp = 22;
 * @return {?proto.validate.TimestampRules}
 */
proto.validate.FieldRules.prototype.getTimestamp = function() {
  return /** @type{?proto.validate.TimestampRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.TimestampRules, 22));
};


/**
 * @param {?proto.validate.TimestampRules|undefined} value
 * @return {!proto.validate.FieldRules} returns this
*/
proto.validate.FieldRules.prototype.setTimestamp = function(value) {
  return jspb.Message.setOneofWrapperField(this, 22, proto.validate.FieldRules.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.FieldRules} returns this
 */
proto.validate.FieldRules.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FieldRules.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 22) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.FloatRules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.FloatRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.FloatRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.FloatRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.FloatRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.FloatRules}
 */
proto.validate.FloatRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.FloatRules;
  return proto.validate.FloatRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.FloatRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.FloatRules}
 */
proto.validate.FloatRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFloat() : [reader.readFloat()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.FloatRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.FloatRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.FloatRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.FloatRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedFloat(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedFloat(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional float const = 1;
 * @return {number}
 */
proto.validate.FloatRules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional float lt = 2;
 * @return {number}
 */
proto.validate.FloatRules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional float lte = 3;
 * @return {number}
 */
proto.validate.FloatRules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional float gt = 4;
 * @return {number}
 */
proto.validate.FloatRules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional float gte = 5;
 * @return {number}
 */
proto.validate.FloatRules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated float in = 6;
 * @return {!Array<number>}
 */
proto.validate.FloatRules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated float not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.FloatRules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.FloatRules} returns this
 */
proto.validate.FloatRules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.FloatRules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.DoubleRules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.DoubleRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.DoubleRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.DoubleRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.DoubleRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getOptionalFloatingPointField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getOptionalFloatingPointField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getOptionalFloatingPointField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getOptionalFloatingPointField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.DoubleRules}
 */
proto.validate.DoubleRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.DoubleRules;
  return proto.validate.DoubleRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.DoubleRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.DoubleRules}
 */
proto.validate.DoubleRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.DoubleRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.DoubleRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.DoubleRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.DoubleRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedDouble(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedDouble(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional double const = 1;
 * @return {number}
 */
proto.validate.DoubleRules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double lt = 2;
 * @return {number}
 */
proto.validate.DoubleRules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double lte = 3;
 * @return {number}
 */
proto.validate.DoubleRules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional double gt = 4;
 * @return {number}
 */
proto.validate.DoubleRules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double gte = 5;
 * @return {number}
 */
proto.validate.DoubleRules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated double in = 6;
 * @return {!Array<number>}
 */
proto.validate.DoubleRules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated double not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.DoubleRules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DoubleRules} returns this
 */
proto.validate.DoubleRules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DoubleRules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.Int32Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.Int32Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.Int32Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.Int32Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Int32Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.Int32Rules}
 */
proto.validate.Int32Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.Int32Rules;
  return proto.validate.Int32Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.Int32Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.Int32Rules}
 */
proto.validate.Int32Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.Int32Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.Int32Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.Int32Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Int32Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int32 const = 1;
 * @return {number}
 */
proto.validate.Int32Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 lt = 2;
 * @return {number}
 */
proto.validate.Int32Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 lte = 3;
 * @return {number}
 */
proto.validate.Int32Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int32 gt = 4;
 * @return {number}
 */
proto.validate.Int32Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 gte = 5;
 * @return {number}
 */
proto.validate.Int32Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated int32 in = 6;
 * @return {!Array<number>}
 */
proto.validate.Int32Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated int32 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.Int32Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int32Rules} returns this
 */
proto.validate.Int32Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int32Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.Int64Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.Int64Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.Int64Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.Int64Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Int64Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.Int64Rules}
 */
proto.validate.Int64Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.Int64Rules;
  return proto.validate.Int64Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.Int64Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.Int64Rules}
 */
proto.validate.Int64Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt64() : [reader.readInt64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.Int64Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.Int64Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.Int64Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Int64Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeInt64(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedInt64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional int64 const = 1;
 * @return {number}
 */
proto.validate.Int64Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 lt = 2;
 * @return {number}
 */
proto.validate.Int64Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 lte = 3;
 * @return {number}
 */
proto.validate.Int64Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional int64 gt = 4;
 * @return {number}
 */
proto.validate.Int64Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 gte = 5;
 * @return {number}
 */
proto.validate.Int64Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated int64 in = 6;
 * @return {!Array<number>}
 */
proto.validate.Int64Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated int64 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.Int64Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Int64Rules} returns this
 */
proto.validate.Int64Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Int64Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.UInt32Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.UInt32Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.UInt32Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.UInt32Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.UInt32Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.UInt32Rules}
 */
proto.validate.UInt32Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.UInt32Rules;
  return proto.validate.UInt32Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.UInt32Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.UInt32Rules}
 */
proto.validate.UInt32Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.UInt32Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.UInt32Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.UInt32Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.UInt32Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedUint32(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedUint32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional uint32 const = 1;
 * @return {number}
 */
proto.validate.UInt32Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 lt = 2;
 * @return {number}
 */
proto.validate.UInt32Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 lte = 3;
 * @return {number}
 */
proto.validate.UInt32Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 gt = 4;
 * @return {number}
 */
proto.validate.UInt32Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 gte = 5;
 * @return {number}
 */
proto.validate.UInt32Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated uint32 in = 6;
 * @return {!Array<number>}
 */
proto.validate.UInt32Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated uint32 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.UInt32Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt32Rules} returns this
 */
proto.validate.UInt32Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt32Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.UInt64Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.UInt64Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.UInt64Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.UInt64Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.UInt64Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.UInt64Rules}
 */
proto.validate.UInt64Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.UInt64Rules;
  return proto.validate.UInt64Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.UInt64Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.UInt64Rules}
 */
proto.validate.UInt64Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.UInt64Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.UInt64Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.UInt64Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.UInt64Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedUint64(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedUint64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional uint64 const = 1;
 * @return {number}
 */
proto.validate.UInt64Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 lt = 2;
 * @return {number}
 */
proto.validate.UInt64Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 lte = 3;
 * @return {number}
 */
proto.validate.UInt64Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 gt = 4;
 * @return {number}
 */
proto.validate.UInt64Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 gte = 5;
 * @return {number}
 */
proto.validate.UInt64Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated uint64 in = 6;
 * @return {!Array<number>}
 */
proto.validate.UInt64Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated uint64 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.UInt64Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.UInt64Rules} returns this
 */
proto.validate.UInt64Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.UInt64Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.SInt32Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.SInt32Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.SInt32Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.SInt32Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SInt32Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.SInt32Rules}
 */
proto.validate.SInt32Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.SInt32Rules;
  return proto.validate.SInt32Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.SInt32Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.SInt32Rules}
 */
proto.validate.SInt32Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint32());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint32() : [reader.readSint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.SInt32Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.SInt32Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.SInt32Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SInt32Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeSint32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeSint32(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedSint32(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedSint32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional sint32 const = 1;
 * @return {number}
 */
proto.validate.SInt32Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sint32 lt = 2;
 * @return {number}
 */
proto.validate.SInt32Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sint32 lte = 3;
 * @return {number}
 */
proto.validate.SInt32Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sint32 gt = 4;
 * @return {number}
 */
proto.validate.SInt32Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sint32 gte = 5;
 * @return {number}
 */
proto.validate.SInt32Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated sint32 in = 6;
 * @return {!Array<number>}
 */
proto.validate.SInt32Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated sint32 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.SInt32Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt32Rules} returns this
 */
proto.validate.SInt32Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt32Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.SInt64Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.SInt64Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.SInt64Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.SInt64Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SInt64Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.SInt64Rules}
 */
proto.validate.SInt64Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.SInt64Rules;
  return proto.validate.SInt64Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.SInt64Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.SInt64Rules}
 */
proto.validate.SInt64Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.SInt64Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.SInt64Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.SInt64Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SInt64Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeSint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeSint64(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedSint64(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedSint64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional sint64 const = 1;
 * @return {number}
 */
proto.validate.SInt64Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sint64 lt = 2;
 * @return {number}
 */
proto.validate.SInt64Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sint64 lte = 3;
 * @return {number}
 */
proto.validate.SInt64Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sint64 gt = 4;
 * @return {number}
 */
proto.validate.SInt64Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sint64 gte = 5;
 * @return {number}
 */
proto.validate.SInt64Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated sint64 in = 6;
 * @return {!Array<number>}
 */
proto.validate.SInt64Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated sint64 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.SInt64Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SInt64Rules} returns this
 */
proto.validate.SInt64Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SInt64Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.Fixed32Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.Fixed32Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.Fixed32Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.Fixed32Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Fixed32Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.Fixed32Rules}
 */
proto.validate.Fixed32Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.Fixed32Rules;
  return proto.validate.Fixed32Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.Fixed32Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.Fixed32Rules}
 */
proto.validate.Fixed32Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFixed32());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed32() : [reader.readFixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed32() : [reader.readFixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.Fixed32Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.Fixed32Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.Fixed32Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Fixed32Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFixed32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFixed32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFixed32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFixed32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFixed32(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedFixed32(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedFixed32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional fixed32 const = 1;
 * @return {number}
 */
proto.validate.Fixed32Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional fixed32 lt = 2;
 * @return {number}
 */
proto.validate.Fixed32Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional fixed32 lte = 3;
 * @return {number}
 */
proto.validate.Fixed32Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional fixed32 gt = 4;
 * @return {number}
 */
proto.validate.Fixed32Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional fixed32 gte = 5;
 * @return {number}
 */
proto.validate.Fixed32Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated fixed32 in = 6;
 * @return {!Array<number>}
 */
proto.validate.Fixed32Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated fixed32 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.Fixed32Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed32Rules} returns this
 */
proto.validate.Fixed32Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed32Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.Fixed64Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.Fixed64Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.Fixed64Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.Fixed64Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Fixed64Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.Fixed64Rules}
 */
proto.validate.Fixed64Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.Fixed64Rules;
  return proto.validate.Fixed64Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.Fixed64Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.Fixed64Rules}
 */
proto.validate.Fixed64Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFixed64());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed64() : [reader.readFixed64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed64() : [reader.readFixed64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.Fixed64Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.Fixed64Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.Fixed64Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.Fixed64Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeFixed64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeFixed64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeFixed64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeFixed64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeFixed64(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedFixed64(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedFixed64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional fixed64 const = 1;
 * @return {number}
 */
proto.validate.Fixed64Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional fixed64 lt = 2;
 * @return {number}
 */
proto.validate.Fixed64Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional fixed64 lte = 3;
 * @return {number}
 */
proto.validate.Fixed64Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional fixed64 gt = 4;
 * @return {number}
 */
proto.validate.Fixed64Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional fixed64 gte = 5;
 * @return {number}
 */
proto.validate.Fixed64Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated fixed64 in = 6;
 * @return {!Array<number>}
 */
proto.validate.Fixed64Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated fixed64 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.Fixed64Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.Fixed64Rules} returns this
 */
proto.validate.Fixed64Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.Fixed64Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.SFixed32Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.SFixed32Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.SFixed32Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.SFixed32Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SFixed32Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.SFixed32Rules}
 */
proto.validate.SFixed32Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.SFixed32Rules;
  return proto.validate.SFixed32Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.SFixed32Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.SFixed32Rules}
 */
proto.validate.SFixed32Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSfixed32());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSfixed32() : [reader.readSfixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSfixed32() : [reader.readSfixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.SFixed32Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.SFixed32Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.SFixed32Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SFixed32Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSfixed32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSfixed32(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeSfixed32(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSfixed32(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeSfixed32(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedSfixed32(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedSfixed32(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional sfixed32 const = 1;
 * @return {number}
 */
proto.validate.SFixed32Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sfixed32 lt = 2;
 * @return {number}
 */
proto.validate.SFixed32Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sfixed32 lte = 3;
 * @return {number}
 */
proto.validate.SFixed32Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sfixed32 gt = 4;
 * @return {number}
 */
proto.validate.SFixed32Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sfixed32 gte = 5;
 * @return {number}
 */
proto.validate.SFixed32Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated sfixed32 in = 6;
 * @return {!Array<number>}
 */
proto.validate.SFixed32Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated sfixed32 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.SFixed32Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed32Rules} returns this
 */
proto.validate.SFixed32Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed32Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.SFixed64Rules.repeatedFields_ = [6,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.SFixed64Rules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.SFixed64Rules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.SFixed64Rules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SFixed64Rules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    lt: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    lte: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    gt: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    gte: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.SFixed64Rules}
 */
proto.validate.SFixed64Rules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.SFixed64Rules;
  return proto.validate.SFixed64Rules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.SFixed64Rules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.SFixed64Rules}
 */
proto.validate.SFixed64Rules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setLt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setLte(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setGt(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readSfixed64());
      msg.setGte(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSfixed64() : [reader.readSfixed64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSfixed64() : [reader.readSfixed64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.SFixed64Rules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.SFixed64Rules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.SFixed64Rules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.SFixed64Rules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeSfixed64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeSfixed64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeSfixed64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeSfixed64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeSfixed64(
      5,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedSfixed64(
      6,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedSfixed64(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional sfixed64 const = 1;
 * @return {number}
 */
proto.validate.SFixed64Rules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional sfixed64 lt = 2;
 * @return {number}
 */
proto.validate.SFixed64Rules.prototype.getLt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setLt = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearLt = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sfixed64 lte = 3;
 * @return {number}
 */
proto.validate.SFixed64Rules.prototype.getLte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setLte = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearLte = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional sfixed64 gt = 4;
 * @return {number}
 */
proto.validate.SFixed64Rules.prototype.getGt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setGt = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearGt = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional sfixed64 gte = 5;
 * @return {number}
 */
proto.validate.SFixed64Rules.prototype.getGte = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setGte = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearGte = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated sfixed64 in = 6;
 * @return {!Array<number>}
 */
proto.validate.SFixed64Rules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated sfixed64 not_in = 7;
 * @return {!Array<number>}
 */
proto.validate.SFixed64Rules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ignore_empty = 8;
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.SFixed64Rules} returns this
 */
proto.validate.SFixed64Rules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.SFixed64Rules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.BoolRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.BoolRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.BoolRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.BoolRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.BoolRules}
 */
proto.validate.BoolRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.BoolRules;
  return proto.validate.BoolRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.BoolRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.BoolRules}
 */
proto.validate.BoolRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setConst(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.BoolRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.BoolRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.BoolRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.BoolRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool const = 1;
 * @return {boolean}
 */
proto.validate.BoolRules.prototype.getConst = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.BoolRules} returns this
 */
proto.validate.BoolRules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BoolRules} returns this
 */
proto.validate.BoolRules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BoolRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.StringRules.repeatedFields_ = [10,11];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.validate.StringRules.oneofGroups_ = [[12,13,14,15,16,17,18,21,22,24]];

/**
 * @enum {number}
 */
proto.validate.StringRules.WellKnownCase = {
  WELL_KNOWN_NOT_SET: 0,
  EMAIL: 12,
  HOSTNAME: 13,
  IP: 14,
  IPV4: 15,
  IPV6: 16,
  URI: 17,
  URI_REF: 18,
  ADDRESS: 21,
  UUID: 22,
  WELL_KNOWN_REGEX: 24
};

/**
 * @return {proto.validate.StringRules.WellKnownCase}
 */
proto.validate.StringRules.prototype.getWellKnownCase = function() {
  return /** @type {proto.validate.StringRules.WellKnownCase} */(jspb.Message.computeOneofCase(this, proto.validate.StringRules.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.StringRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.StringRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.StringRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.StringRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    len: (f = jspb.Message.getField(msg, 19)) == null ? undefined : f,
    minLen: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    maxLen: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    lenBytes: (f = jspb.Message.getField(msg, 20)) == null ? undefined : f,
    minBytes: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    maxBytes: (f = jspb.Message.getField(msg, 5)) == null ? undefined : f,
    pattern: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    prefix: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    suffix: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    contains: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    notContains: (f = jspb.Message.getField(msg, 23)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    email: (f = jspb.Message.getBooleanField(msg, 12)) == null ? undefined : f,
    hostname: (f = jspb.Message.getBooleanField(msg, 13)) == null ? undefined : f,
    ip: (f = jspb.Message.getBooleanField(msg, 14)) == null ? undefined : f,
    ipv4: (f = jspb.Message.getBooleanField(msg, 15)) == null ? undefined : f,
    ipv6: (f = jspb.Message.getBooleanField(msg, 16)) == null ? undefined : f,
    uri: (f = jspb.Message.getBooleanField(msg, 17)) == null ? undefined : f,
    uriRef: (f = jspb.Message.getBooleanField(msg, 18)) == null ? undefined : f,
    address: (f = jspb.Message.getBooleanField(msg, 21)) == null ? undefined : f,
    uuid: (f = jspb.Message.getBooleanField(msg, 22)) == null ? undefined : f,
    wellKnownRegex: (f = jspb.Message.getField(msg, 24)) == null ? undefined : f,
    strict: jspb.Message.getBooleanFieldWithDefault(msg, 25, true),
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 26)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.StringRules}
 */
proto.validate.StringRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.StringRules;
  return proto.validate.StringRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.StringRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.StringRules}
 */
proto.validate.StringRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setConst(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLen(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinLen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxLen(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLenBytes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinBytes(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxBytes(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrefix(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSuffix(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setContains(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setNotContains(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addIn(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotIn(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmail(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHostname(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIp(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIpv4(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIpv6(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUri(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUriRef(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddress(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUuid(value);
      break;
    case 24:
      var value = /** @type {!proto.validate.KnownRegex} */ (reader.readEnum());
      msg.setWellKnownRegex(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStrict(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.StringRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.StringRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.StringRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.StringRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeUint64(
      19,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeUint64(
      4,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint64(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeBool(
      13,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeBool(
      15,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeBool(
      16,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeBool(
      17,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeBool(
      18,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeBool(
      21,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeBool(
      22,
      f
    );
  }
  f = /** @type {!proto.validate.KnownRegex} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeEnum(
      24,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeBool(
      25,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 26));
  if (f != null) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional string const = 1;
 * @return {string}
 */
proto.validate.StringRules.prototype.getConst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 len = 19;
 * @return {number}
 */
proto.validate.StringRules.prototype.getLen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setLen = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearLen = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasLen = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional uint64 min_len = 2;
 * @return {number}
 */
proto.validate.StringRules.prototype.getMinLen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setMinLen = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearMinLen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasMinLen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 max_len = 3;
 * @return {number}
 */
proto.validate.StringRules.prototype.getMaxLen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setMaxLen = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearMaxLen = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasMaxLen = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint64 len_bytes = 20;
 * @return {number}
 */
proto.validate.StringRules.prototype.getLenBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setLenBytes = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearLenBytes = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasLenBytes = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional uint64 min_bytes = 4;
 * @return {number}
 */
proto.validate.StringRules.prototype.getMinBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setMinBytes = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearMinBytes = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasMinBytes = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint64 max_bytes = 5;
 * @return {number}
 */
proto.validate.StringRules.prototype.getMaxBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setMaxBytes = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearMaxBytes = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasMaxBytes = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string pattern = 6;
 * @return {string}
 */
proto.validate.StringRules.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setPattern = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearPattern = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasPattern = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string prefix = 7;
 * @return {string}
 */
proto.validate.StringRules.prototype.getPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setPrefix = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearPrefix = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasPrefix = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string suffix = 8;
 * @return {string}
 */
proto.validate.StringRules.prototype.getSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setSuffix = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearSuffix = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasSuffix = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string contains = 9;
 * @return {string}
 */
proto.validate.StringRules.prototype.getContains = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setContains = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearContains = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasContains = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string not_contains = 23;
 * @return {string}
 */
proto.validate.StringRules.prototype.getNotContains = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setNotContains = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearNotContains = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasNotContains = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * repeated string in = 10;
 * @return {!Array<string>}
 */
proto.validate.StringRules.prototype.getInList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated string not_in = 11;
 * @return {!Array<string>}
 */
proto.validate.StringRules.prototype.getNotInList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool email = 12;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getEmail = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setEmail = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearEmail = function() {
  return jspb.Message.setOneofField(this, 12, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool hostname = 13;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getHostname = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setHostname = function(value) {
  return jspb.Message.setOneofField(this, 13, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearHostname = function() {
  return jspb.Message.setOneofField(this, 13, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasHostname = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool ip = 14;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getIp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setIp = function(value) {
  return jspb.Message.setOneofField(this, 14, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearIp = function() {
  return jspb.Message.setOneofField(this, 14, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasIp = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional bool ipv4 = 15;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getIpv4 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setIpv4 = function(value) {
  return jspb.Message.setOneofField(this, 15, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearIpv4 = function() {
  return jspb.Message.setOneofField(this, 15, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasIpv4 = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bool ipv6 = 16;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getIpv6 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setIpv6 = function(value) {
  return jspb.Message.setOneofField(this, 16, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearIpv6 = function() {
  return jspb.Message.setOneofField(this, 16, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasIpv6 = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional bool uri = 17;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getUri = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setUri = function(value) {
  return jspb.Message.setOneofField(this, 17, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearUri = function() {
  return jspb.Message.setOneofField(this, 17, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasUri = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional bool uri_ref = 18;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getUriRef = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setUriRef = function(value) {
  return jspb.Message.setOneofField(this, 18, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearUriRef = function() {
  return jspb.Message.setOneofField(this, 18, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasUriRef = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool address = 21;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getAddress = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setAddress = function(value) {
  return jspb.Message.setOneofField(this, 21, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearAddress = function() {
  return jspb.Message.setOneofField(this, 21, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional bool uuid = 22;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getUuid = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setUuid = function(value) {
  return jspb.Message.setOneofField(this, 22, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearUuid = function() {
  return jspb.Message.setOneofField(this, 22, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasUuid = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional KnownRegex well_known_regex = 24;
 * @return {!proto.validate.KnownRegex}
 */
proto.validate.StringRules.prototype.getWellKnownRegex = function() {
  return /** @type {!proto.validate.KnownRegex} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {!proto.validate.KnownRegex} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setWellKnownRegex = function(value) {
  return jspb.Message.setOneofField(this, 24, proto.validate.StringRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearWellKnownRegex = function() {
  return jspb.Message.setOneofField(this, 24, proto.validate.StringRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasWellKnownRegex = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool strict = 25;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getStrict = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, true));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setStrict = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearStrict = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasStrict = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional bool ignore_empty = 26;
 * @return {boolean}
 */
proto.validate.StringRules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 26, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.StringRules} returns this
 */
proto.validate.StringRules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 26, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.StringRules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 26) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.BytesRules.repeatedFields_ = [8,9];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.validate.BytesRules.oneofGroups_ = [[10,11,12]];

/**
 * @enum {number}
 */
proto.validate.BytesRules.WellKnownCase = {
  WELL_KNOWN_NOT_SET: 0,
  IP: 10,
  IPV4: 11,
  IPV6: 12
};

/**
 * @return {proto.validate.BytesRules.WellKnownCase}
 */
proto.validate.BytesRules.prototype.getWellKnownCase = function() {
  return /** @type {proto.validate.BytesRules.WellKnownCase} */(jspb.Message.computeOneofCase(this, proto.validate.BytesRules.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.BytesRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.BytesRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.BytesRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.BytesRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: msg.getConst_asB64(),
    len: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    minLen: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    maxLen: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    pattern: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    prefix: msg.getPrefix_asB64(),
    suffix: msg.getSuffix_asB64(),
    contains: msg.getContains_asB64(),
    inList: msg.getInList_asB64(),
    notInList: msg.getNotInList_asB64(),
    ip: (f = jspb.Message.getBooleanField(msg, 10)) == null ? undefined : f,
    ipv4: (f = jspb.Message.getBooleanField(msg, 11)) == null ? undefined : f,
    ipv6: (f = jspb.Message.getBooleanField(msg, 12)) == null ? undefined : f,
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 14)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.BytesRules}
 */
proto.validate.BytesRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.BytesRules;
  return proto.validate.BytesRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.BytesRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.BytesRules}
 */
proto.validate.BytesRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setConst(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setLen(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinLen(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxLen(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPrefix(value);
      break;
    case 6:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSuffix(value);
      break;
    case 7:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setContains(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addIn(value);
      break;
    case 9:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addNotIn(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIp(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIpv4(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIpv6(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.BytesRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.BytesRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.BytesRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.BytesRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeUint64(
      13,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBytes(
      5,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBytes(
      6,
      f
    );
  }
  f = /** @type {!(string|Uint8Array)} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBytes(
      7,
      f
    );
  }
  f = message.getInList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      8,
      f
    );
  }
  f = message.getNotInList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeBool(
      11,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeBool(
      12,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeBool(
      14,
      f
    );
  }
};


/**
 * optional bytes const = 1;
 * @return {string}
 */
proto.validate.BytesRules.prototype.getConst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes const = 1;
 * This is a type-conversion wrapper around `getConst()`
 * @return {string}
 */
proto.validate.BytesRules.prototype.getConst_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getConst()));
};


/**
 * optional bytes const = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getConst()`
 * @return {!Uint8Array}
 */
proto.validate.BytesRules.prototype.getConst_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getConst()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 len = 13;
 * @return {number}
 */
proto.validate.BytesRules.prototype.getLen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setLen = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearLen = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasLen = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional uint64 min_len = 2;
 * @return {number}
 */
proto.validate.BytesRules.prototype.getMinLen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setMinLen = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearMinLen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasMinLen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint64 max_len = 3;
 * @return {number}
 */
proto.validate.BytesRules.prototype.getMaxLen = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setMaxLen = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearMaxLen = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasMaxLen = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string pattern = 4;
 * @return {string}
 */
proto.validate.BytesRules.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setPattern = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearPattern = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasPattern = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bytes prefix = 5;
 * @return {string}
 */
proto.validate.BytesRules.prototype.getPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes prefix = 5;
 * This is a type-conversion wrapper around `getPrefix()`
 * @return {string}
 */
proto.validate.BytesRules.prototype.getPrefix_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPrefix()));
};


/**
 * optional bytes prefix = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPrefix()`
 * @return {!Uint8Array}
 */
proto.validate.BytesRules.prototype.getPrefix_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPrefix()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setPrefix = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearPrefix = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasPrefix = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes suffix = 6;
 * @return {string}
 */
proto.validate.BytesRules.prototype.getSuffix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * optional bytes suffix = 6;
 * This is a type-conversion wrapper around `getSuffix()`
 * @return {string}
 */
proto.validate.BytesRules.prototype.getSuffix_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSuffix()));
};


/**
 * optional bytes suffix = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSuffix()`
 * @return {!Uint8Array}
 */
proto.validate.BytesRules.prototype.getSuffix_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSuffix()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setSuffix = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearSuffix = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasSuffix = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bytes contains = 7;
 * @return {string}
 */
proto.validate.BytesRules.prototype.getContains = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * optional bytes contains = 7;
 * This is a type-conversion wrapper around `getContains()`
 * @return {string}
 */
proto.validate.BytesRules.prototype.getContains_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getContains()));
};


/**
 * optional bytes contains = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getContains()`
 * @return {!Uint8Array}
 */
proto.validate.BytesRules.prototype.getContains_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getContains()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setContains = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearContains = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasContains = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * repeated bytes in = 8;
 * @return {!Array<string>}
 */
proto.validate.BytesRules.prototype.getInList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * repeated bytes in = 8;
 * This is a type-conversion wrapper around `getInList()`
 * @return {!Array<string>}
 */
proto.validate.BytesRules.prototype.getInList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getInList()));
};


/**
 * repeated bytes in = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getInList()`
 * @return {!Array<!Uint8Array>}
 */
proto.validate.BytesRules.prototype.getInList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getInList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated bytes not_in = 9;
 * @return {!Array<string>}
 */
proto.validate.BytesRules.prototype.getNotInList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * repeated bytes not_in = 9;
 * This is a type-conversion wrapper around `getNotInList()`
 * @return {!Array<string>}
 */
proto.validate.BytesRules.prototype.getNotInList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getNotInList()));
};


/**
 * repeated bytes not_in = 9;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNotInList()`
 * @return {!Array<!Uint8Array>}
 */
proto.validate.BytesRules.prototype.getNotInList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getNotInList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};


/**
 * optional bool ip = 10;
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.getIp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setIp = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.validate.BytesRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearIp = function() {
  return jspb.Message.setOneofField(this, 10, proto.validate.BytesRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasIp = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool ipv4 = 11;
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.getIpv4 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setIpv4 = function(value) {
  return jspb.Message.setOneofField(this, 11, proto.validate.BytesRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearIpv4 = function() {
  return jspb.Message.setOneofField(this, 11, proto.validate.BytesRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasIpv4 = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional bool ipv6 = 12;
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.getIpv6 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setIpv6 = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.validate.BytesRules.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearIpv6 = function() {
  return jspb.Message.setOneofField(this, 12, proto.validate.BytesRules.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasIpv6 = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool ignore_empty = 14;
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.BytesRules} returns this
 */
proto.validate.BytesRules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.BytesRules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.EnumRules.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.EnumRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.EnumRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.EnumRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.EnumRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_const: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    definedOnly: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.EnumRules}
 */
proto.validate.EnumRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.EnumRules;
  return proto.validate.EnumRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.EnumRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.EnumRules}
 */
proto.validate.EnumRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setConst(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefinedOnly(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addIn(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNotIn(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.EnumRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.EnumRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.EnumRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.EnumRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(
      3,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 const = 1;
 * @return {number}
 */
proto.validate.EnumRules.prototype.getConst = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.setConst = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.clearConst = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.EnumRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool defined_only = 2;
 * @return {boolean}
 */
proto.validate.EnumRules.prototype.getDefinedOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.setDefinedOnly = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.clearDefinedOnly = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.EnumRules.prototype.hasDefinedOnly = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated int32 in = 3;
 * @return {!Array<number>}
 */
proto.validate.EnumRules.prototype.getInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated int32 not_in = 4;
 * @return {!Array<number>}
 */
proto.validate.EnumRules.prototype.getNotInList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.EnumRules} returns this
 */
proto.validate.EnumRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.MessageRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.MessageRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.MessageRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.MessageRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    skip: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    required: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.MessageRules}
 */
proto.validate.MessageRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.MessageRules;
  return proto.validate.MessageRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.MessageRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.MessageRules}
 */
proto.validate.MessageRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkip(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.MessageRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.MessageRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.MessageRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.MessageRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool skip = 1;
 * @return {boolean}
 */
proto.validate.MessageRules.prototype.getSkip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.MessageRules} returns this
 */
proto.validate.MessageRules.prototype.setSkip = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.MessageRules} returns this
 */
proto.validate.MessageRules.prototype.clearSkip = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MessageRules.prototype.hasSkip = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool required = 2;
 * @return {boolean}
 */
proto.validate.MessageRules.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.MessageRules} returns this
 */
proto.validate.MessageRules.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.MessageRules} returns this
 */
proto.validate.MessageRules.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MessageRules.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.RepeatedRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.RepeatedRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.RepeatedRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.RepeatedRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    minItems: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    maxItems: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    unique: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    items: (f = msg.getItems()) && proto.validate.FieldRules.toObject(includeInstance, f),
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.RepeatedRules}
 */
proto.validate.RepeatedRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.RepeatedRules;
  return proto.validate.RepeatedRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.RepeatedRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.RepeatedRules}
 */
proto.validate.RepeatedRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinItems(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxItems(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnique(value);
      break;
    case 4:
      var value = new proto.validate.FieldRules;
      reader.readMessage(value,proto.validate.FieldRules.deserializeBinaryFromReader);
      msg.setItems(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.RepeatedRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.RepeatedRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.RepeatedRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.RepeatedRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getItems();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.validate.FieldRules.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional uint64 min_items = 1;
 * @return {number}
 */
proto.validate.RepeatedRules.prototype.getMinItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.setMinItems = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.clearMinItems = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.hasMinItems = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 max_items = 2;
 * @return {number}
 */
proto.validate.RepeatedRules.prototype.getMaxItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.setMaxItems = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.clearMaxItems = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.hasMaxItems = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool unique = 3;
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.getUnique = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.setUnique = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.clearUnique = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.hasUnique = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FieldRules items = 4;
 * @return {?proto.validate.FieldRules}
 */
proto.validate.RepeatedRules.prototype.getItems = function() {
  return /** @type{?proto.validate.FieldRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.FieldRules, 4));
};


/**
 * @param {?proto.validate.FieldRules|undefined} value
 * @return {!proto.validate.RepeatedRules} returns this
*/
proto.validate.RepeatedRules.prototype.setItems = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.clearItems = function() {
  return this.setItems(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.hasItems = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool ignore_empty = 5;
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.RepeatedRules} returns this
 */
proto.validate.RepeatedRules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.RepeatedRules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.MapRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.MapRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.MapRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.MapRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    minPairs: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    maxPairs: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    noSparse: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    keys: (f = msg.getKeys()) && proto.validate.FieldRules.toObject(includeInstance, f),
    values: (f = msg.getValues()) && proto.validate.FieldRules.toObject(includeInstance, f),
    ignoreEmpty: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.MapRules}
 */
proto.validate.MapRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.MapRules;
  return proto.validate.MapRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.MapRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.MapRules}
 */
proto.validate.MapRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinPairs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxPairs(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoSparse(value);
      break;
    case 4:
      var value = new proto.validate.FieldRules;
      reader.readMessage(value,proto.validate.FieldRules.deserializeBinaryFromReader);
      msg.setKeys(value);
      break;
    case 5:
      var value = new proto.validate.FieldRules;
      reader.readMessage(value,proto.validate.FieldRules.deserializeBinaryFromReader);
      msg.setValues(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIgnoreEmpty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.MapRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.MapRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.MapRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.MapRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getKeys();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.validate.FieldRules.serializeBinaryToWriter
    );
  }
  f = message.getValues();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.validate.FieldRules.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional uint64 min_pairs = 1;
 * @return {number}
 */
proto.validate.MapRules.prototype.getMinPairs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.setMinPairs = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.clearMinPairs = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MapRules.prototype.hasMinPairs = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 max_pairs = 2;
 * @return {number}
 */
proto.validate.MapRules.prototype.getMaxPairs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.setMaxPairs = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.clearMaxPairs = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MapRules.prototype.hasMaxPairs = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool no_sparse = 3;
 * @return {boolean}
 */
proto.validate.MapRules.prototype.getNoSparse = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.setNoSparse = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.clearNoSparse = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MapRules.prototype.hasNoSparse = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FieldRules keys = 4;
 * @return {?proto.validate.FieldRules}
 */
proto.validate.MapRules.prototype.getKeys = function() {
  return /** @type{?proto.validate.FieldRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.FieldRules, 4));
};


/**
 * @param {?proto.validate.FieldRules|undefined} value
 * @return {!proto.validate.MapRules} returns this
*/
proto.validate.MapRules.prototype.setKeys = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.clearKeys = function() {
  return this.setKeys(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MapRules.prototype.hasKeys = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional FieldRules values = 5;
 * @return {?proto.validate.FieldRules}
 */
proto.validate.MapRules.prototype.getValues = function() {
  return /** @type{?proto.validate.FieldRules} */ (
    jspb.Message.getWrapperField(this, proto.validate.FieldRules, 5));
};


/**
 * @param {?proto.validate.FieldRules|undefined} value
 * @return {!proto.validate.MapRules} returns this
*/
proto.validate.MapRules.prototype.setValues = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.clearValues = function() {
  return this.setValues(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MapRules.prototype.hasValues = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool ignore_empty = 6;
 * @return {boolean}
 */
proto.validate.MapRules.prototype.getIgnoreEmpty = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.setIgnoreEmpty = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.MapRules} returns this
 */
proto.validate.MapRules.prototype.clearIgnoreEmpty = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.MapRules.prototype.hasIgnoreEmpty = function() {
  return jspb.Message.getField(this, 6) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.AnyRules.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.AnyRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.AnyRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.AnyRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.AnyRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    required: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    inList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    notInList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.AnyRules}
 */
proto.validate.AnyRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.AnyRules;
  return proto.validate.AnyRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.AnyRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.AnyRules}
 */
proto.validate.AnyRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addIn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addNotIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.AnyRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.AnyRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.AnyRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.AnyRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.validate.AnyRules.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.AnyRules.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string in = 2;
 * @return {!Array<string>}
 */
proto.validate.AnyRules.prototype.getInList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.setInList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.addIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated string not_in = 3;
 * @return {!Array<string>}
 */
proto.validate.AnyRules.prototype.getNotInList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.setNotInList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.addNotIn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.AnyRules} returns this
 */
proto.validate.AnyRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.validate.DurationRules.repeatedFields_ = [7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.DurationRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.DurationRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.DurationRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.DurationRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    required: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    pb_const: (f = msg.getConst()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    lt: (f = msg.getLt()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    lte: (f = msg.getLte()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    gt: (f = msg.getGt()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    gte: (f = msg.getGte()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
    inList: jspb.Message.toObjectList(msg.getInList(),
    google_protobuf_duration_pb.Duration.toObject, includeInstance),
    notInList: jspb.Message.toObjectList(msg.getNotInList(),
    google_protobuf_duration_pb.Duration.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.DurationRules}
 */
proto.validate.DurationRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.DurationRules;
  return proto.validate.DurationRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.DurationRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.DurationRules}
 */
proto.validate.DurationRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 2:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setConst(value);
      break;
    case 3:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setLt(value);
      break;
    case 4:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setLte(value);
      break;
    case 5:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setGt(value);
      break;
    case 6:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setGte(value);
      break;
    case 7:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.addIn(value);
      break;
    case 8:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.addNotIn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.DurationRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.DurationRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.DurationRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.DurationRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConst();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getLt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getLte();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getGt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getGte();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getInList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
  f = message.getNotInList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Duration const = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.getConst = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setConst = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearConst = function() {
  return this.setConst(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Duration lt = 3;
 * @return {?proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.getLt = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 3));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setLt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearLt = function() {
  return this.setLt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Duration lte = 4;
 * @return {?proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.getLte = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 4));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setLte = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearLte = function() {
  return this.setLte(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Duration gt = 5;
 * @return {?proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.getGt = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 5));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setGt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearGt = function() {
  return this.setGt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Duration gte = 6;
 * @return {?proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.getGte = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 6));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setGte = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearGte = function() {
  return this.setGte(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.DurationRules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated google.protobuf.Duration in = 7;
 * @return {!Array<!proto.google.protobuf.Duration>}
 */
proto.validate.DurationRules.prototype.getInList = function() {
  return /** @type{!Array<!proto.google.protobuf.Duration>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_duration_pb.Duration, 7));
};


/**
 * @param {!Array<!proto.google.protobuf.Duration>} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setInList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.google.protobuf.Duration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.addIn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.google.protobuf.Duration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearInList = function() {
  return this.setInList([]);
};


/**
 * repeated google.protobuf.Duration not_in = 8;
 * @return {!Array<!proto.google.protobuf.Duration>}
 */
proto.validate.DurationRules.prototype.getNotInList = function() {
  return /** @type{!Array<!proto.google.protobuf.Duration>} */ (
    jspb.Message.getRepeatedWrapperField(this, google_protobuf_duration_pb.Duration, 8));
};


/**
 * @param {!Array<!proto.google.protobuf.Duration>} value
 * @return {!proto.validate.DurationRules} returns this
*/
proto.validate.DurationRules.prototype.setNotInList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.google.protobuf.Duration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.google.protobuf.Duration}
 */
proto.validate.DurationRules.prototype.addNotIn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.google.protobuf.Duration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.validate.DurationRules} returns this
 */
proto.validate.DurationRules.prototype.clearNotInList = function() {
  return this.setNotInList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.validate.TimestampRules.prototype.toObject = function(opt_includeInstance) {
  return proto.validate.TimestampRules.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.validate.TimestampRules} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.TimestampRules.toObject = function(includeInstance, msg) {
  var f, obj = {
    required: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    pb_const: (f = msg.getConst()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lt: (f = msg.getLt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lte: (f = msg.getLte()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gt: (f = msg.getGt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    gte: (f = msg.getGte()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    ltNow: (f = jspb.Message.getBooleanField(msg, 7)) == null ? undefined : f,
    gtNow: (f = jspb.Message.getBooleanField(msg, 8)) == null ? undefined : f,
    within: (f = msg.getWithin()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.validate.TimestampRules}
 */
proto.validate.TimestampRules.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.validate.TimestampRules;
  return proto.validate.TimestampRules.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.validate.TimestampRules} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.validate.TimestampRules}
 */
proto.validate.TimestampRules.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setConst(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLt(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLte(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setGt(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setGte(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLtNow(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGtNow(value);
      break;
    case 9:
      var value = new google_protobuf_duration_pb.Duration;
      reader.readMessage(value,google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
      msg.setWithin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.validate.TimestampRules.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.validate.TimestampRules.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.validate.TimestampRules} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.validate.TimestampRules.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getConst();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLt();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLte();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getGte();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeBool(
      7,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getWithin();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_duration_pb.Duration.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool required = 1;
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp const = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.validate.TimestampRules.prototype.getConst = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.validate.TimestampRules} returns this
*/
proto.validate.TimestampRules.prototype.setConst = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearConst = function() {
  return this.setConst(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasConst = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp lt = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.validate.TimestampRules.prototype.getLt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.validate.TimestampRules} returns this
*/
proto.validate.TimestampRules.prototype.setLt = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearLt = function() {
  return this.setLt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasLt = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp lte = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.validate.TimestampRules.prototype.getLte = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.validate.TimestampRules} returns this
*/
proto.validate.TimestampRules.prototype.setLte = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearLte = function() {
  return this.setLte(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasLte = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Timestamp gt = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.validate.TimestampRules.prototype.getGt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.validate.TimestampRules} returns this
*/
proto.validate.TimestampRules.prototype.setGt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearGt = function() {
  return this.setGt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasGt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp gte = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.validate.TimestampRules.prototype.getGte = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.validate.TimestampRules} returns this
*/
proto.validate.TimestampRules.prototype.setGte = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearGte = function() {
  return this.setGte(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasGte = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool lt_now = 7;
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.getLtNow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.setLtNow = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearLtNow = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasLtNow = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool gt_now = 8;
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.getGtNow = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.setGtNow = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearGtNow = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasGtNow = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Duration within = 9;
 * @return {?proto.google.protobuf.Duration}
 */
proto.validate.TimestampRules.prototype.getWithin = function() {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 9));
};


/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.validate.TimestampRules} returns this
*/
proto.validate.TimestampRules.prototype.setWithin = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.validate.TimestampRules} returns this
 */
proto.validate.TimestampRules.prototype.clearWithin = function() {
  return this.setWithin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.validate.TimestampRules.prototype.hasWithin = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * @enum {number}
 */
proto.validate.KnownRegex = {
  UNKNOWN: 0,
  HTTP_HEADER_NAME: 1,
  HTTP_HEADER_VALUE: 2
};


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `disabled`.
 * @type {!jspb.ExtensionFieldInfo<boolean>}
 */
proto.validate.disabled = new jspb.ExtensionFieldInfo(
    1071,
    {disabled: 0},
    null,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         null),
    0);

google_protobuf_descriptor_pb.MessageOptions.extensionsBinary[1071] = new jspb.ExtensionFieldBinaryInfo(
    proto.validate.disabled,
    jspb.BinaryReader.prototype.readBool,
    jspb.BinaryWriter.prototype.writeBool,
    undefined,
    undefined,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.MessageOptions.extensions[1071] = proto.validate.disabled;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `ignored`.
 * @type {!jspb.ExtensionFieldInfo<boolean>}
 */
proto.validate.ignored = new jspb.ExtensionFieldInfo(
    1072,
    {ignored: 0},
    null,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         null),
    0);

google_protobuf_descriptor_pb.MessageOptions.extensionsBinary[1072] = new jspb.ExtensionFieldBinaryInfo(
    proto.validate.ignored,
    jspb.BinaryReader.prototype.readBool,
    jspb.BinaryWriter.prototype.writeBool,
    undefined,
    undefined,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.MessageOptions.extensions[1072] = proto.validate.ignored;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `required`.
 * @type {!jspb.ExtensionFieldInfo<boolean>}
 */
proto.validate.required = new jspb.ExtensionFieldInfo(
    1071,
    {required: 0},
    null,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         null),
    0);

google_protobuf_descriptor_pb.OneofOptions.extensionsBinary[1071] = new jspb.ExtensionFieldBinaryInfo(
    proto.validate.required,
    jspb.BinaryReader.prototype.readBool,
    jspb.BinaryWriter.prototype.writeBool,
    undefined,
    undefined,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.OneofOptions.extensions[1071] = proto.validate.required;


/**
 * A tuple of {field number, class constructor} for the extension
 * field named `rules`.
 * @type {!jspb.ExtensionFieldInfo<!proto.validate.FieldRules>}
 */
proto.validate.rules = new jspb.ExtensionFieldInfo(
    1071,
    {rules: 0},
    proto.validate.FieldRules,
     /** @type {?function((boolean|undefined),!jspb.Message=): !Object} */ (
         proto.validate.FieldRules.toObject),
    0);

google_protobuf_descriptor_pb.FieldOptions.extensionsBinary[1071] = new jspb.ExtensionFieldBinaryInfo(
    proto.validate.rules,
    jspb.BinaryReader.prototype.readMessage,
    jspb.BinaryWriter.prototype.writeMessage,
    proto.validate.FieldRules.serializeBinaryToWriter,
    proto.validate.FieldRules.deserializeBinaryFromReader,
    false);
// This registers the extension field with the extended class, so that
// toObject() will function correctly.
google_protobuf_descriptor_pb.FieldOptions.extensions[1071] = proto.validate.rules;

goog.object.extend(exports, proto.validate);
